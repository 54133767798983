import { css, styled } from 'styled-components';
import { themeBase } from 'constants/styles/global';
import { media } from 'styles/utils/mediaQueries';

import Paper from '@mui/material/Paper';

import savings from 'assets/images/products/spv-simple/landing-page/savings.png';
import earth from 'assets/images/products/spv-simple/landing-page/earth.png';
import energy from 'assets/images/products/spv-simple/landing-page/energy.png';
import tech from 'assets/images/products/spv-simple/landing-page/tech.png';
import cardBg from 'assets/images/products/spv-simple/landing-page/card-bg.png';
import gradientBgPhone from 'assets/images/products/spv-simple/landing-page/gradient-bg-phone.png';

const buttonStepsCSS = css`
    color: #202020;
    //font-family: "Poppins";
    font-size: 0.875rem;
    font-weight: 500;
    //letter-spacing: 0.1em;
    //text-transform: uppercase;
    column-gap: 0.625rem;
    padding: 0;

    &:hover {
        background-color: unset;
    }
`;

const stepsMenuItemCSS = css`
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    color: ${themeBase.color.neutral600};
    background-color: ${themeBase.color.neutral100};
    border: 1px solid #cccccc;
    border-radius: 5px;
`;

const housingTypeButtonCSS = css`
    border: 1px solid #dcdcdc;
    background-color: var(--paper-color);
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    padding: 1rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    row-gap: 1.25rem;
    width: clamp(120px, 100%, 240px);

    @media (min-width: 660px) {
        width: 132px;
        height: 162px;
    }

    label {
        text-transform: none;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: var(--primary-color);
        cursor: pointer;

        @media (min-width: 660px) {
            font-size: 0.875rem;
        }
    }

    svg {
        min-height: 73px;
    }

    &:hover {
        background-color: var(--primary-color-bleached);
        border-color: var(--primary-color);

        label {
            color: var(--primary-color);
        }
    }
`;

const housingTypeContainerCSS = css`
    padding: var(--spacing-400) var(--spacing-600) var(--spacing-600);
    border: 1px solid #e5e5e5;
    background-color: var(--paper-color);
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    flex-flow: column nowrap;

    h2 {
        //font-family: "Ping LCG", 'Open Sans';
        font-weight: ${themeBase.fontWeight.bold};
        font-size: 1.142857rem;
        text-align: center;
        margin-bottom: 1.125rem;

        @media (min-width: 660px) {
            text-align: left;
            font-size: 1rem;
        }
    }

    .housing-type-buttons {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: var(--spacing-500);
        padding-inline: 2.571428rem;

        @media (min-width: 660px) {
            flex-flow: row nowrap;
            gap: var(--spacing-600);
            padding-inline: 0;
        }

        .housing-type-button {
            ${housingTypeButtonCSS};

            &.selected {
                ${housingTypeButtonCSS};
                background-color: var(--secondary-color-bleached, --primary-color-bleached);
                border: 1px solid var(--secondary-color, --primary-color);

                label {
                    color: var(--secondary-color, --primary-color);
                    font-weight: 500;
                }

                svg:not(.selected) {
                    path {
                        fill: var(--secondary-color, --primary-color);
                    }
                }
            }
        }
    }
`;

const numberPhasesButtonCSS = css`
    min-width: 144px;
    min-height: 51px;
    border: 1px solid ${themeBase.color.neutral400};
    border-radius: 5px;
    color: var(--primary-color);
    padding: 0.625rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.625rem;
`;

const inputContainerCSS = css`
    display: flex;
    flex-flow: column nowrap;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    padding-right: 2rem;
    margin-bottom: 1.5rem;

    label {
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: var(--text-color);
        font-size: 0.875rem;
        flex: 1;
    }
`;

const radioGroupCSS = css`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    column-gap: 1rem;

    @media screen and (max-width: 550px) {
        flex-flow: wrap;
    }

    .radio-button {
        flex: 1;
        padding: 0.5rem 1rem;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0.625rem;
        background-color: var(--paper-color);
        border: 1px solid ${themeBase.color.neutral400};
        border-radius: 0.25rem;
        pointer-events: all;

        .MuiTypography-root {
            min-width: fit-content;
        }

        @media screen and (max-width: 1366px) {
            padding: 0.5rem;
        }

        .MuiFormControlLabel-label {
            display: flex;
            flex-flow: nowrap;
            justify-content: flex-start;
            align-items: center;
            font-size: 0.65rem;
            font-weight: ${themeBase.fontWeight.semibold};
            svg {
                margin-right: 10px;
                width: 28px;
            }
            span {
                width: 100%;
            }
        }

        &.checked {
            svg.checkbox {
                rect {
                    stroke: var(--secondary-color);
                }

                path {
                    fill: var(--secondary-color);
                    stroke: var(--secondary-color);
                }
            }
        }
    }
`;

const simulationCardCSS = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 1.5rem;
    width: 100%;
    height: 100%;
    position: relative;

    .simulation-card-banner {
        position: absolute;
        top: -1rem;
        right: -1rem;
        min-width: 117px;
        background-color: rgba(0, 15, 30, 0.1);
        color: var(--primary-color);
        padding: 0.5rem 1rem;
        border-radius: 0px 5px 0px 10px;
        font-size: 0.75rem;
        font-weight: ${themeBase.fontWeight.normal};

        @media (min-width: 1366px) {
            right: -1.5rem;
        }
    }

    .simulation-card-header {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        margin-top: 0.75rem;

        .image {
            width: 107px;
            height: auto;
            margin: auto;
        }

        .title {
            font-size: 1rem;
            font-weight: ${themeBase.fontWeight.normal};
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: var(--spacing-150);

            svg {
                width: 1.5rem;
                height: 1.5rem;

                @media screen and (min-width: 1366px) {
                    width: 2rem;
                    height: 2rem;
                }

                path {
                    fill: var(--primary-color);
                }
            }

            @media screen and (max-width: 1366px) {
                font-size: var(--spacing-400);
            }
        }

        .description {
            font-size: 0.875rem;
            font-weight: ${themeBase.fontWeight.normal};
            line-height: 1rem;

            @media screen and (min-width: 1366px) {
                min-height: 2rem; // line-height * 2 (description takes two lines max.)
            }
        }
    }

    .simulation-card-body {
        width: 100%;
        color: var(--primary-color);
        flex-grow: 1;

        &-special {
            width: 100%;

            .radio-group {
                ${radioGroupCSS};
            }

            .radio-group-prices {
                ${radioGroupCSS};
                justify-content: center;

                .radio-button {
                    justify-content: center;
                    padding: 0.5rem;
                    margin-bottom: 0.5rem;

                    .MuiFormControlLabel-label {
                        justify-content: center;

                        .label-composed {
                            display: flex;
                            flex-flow: column nowrap;
                            justify-content: center;
                            align-items: center;
                            row-gap: 0.25rem;

                            .label {
                                &-small {
                                    font-size: var(--spacing-200);
                                    font-weight: ${themeBase.fontWeight.normal};

                                    @media screen and (max-width: 1366px) {
                                        font-size: var(--spacing-150);
                                    }
                                }

                                &-big {
                                    display: flex;
                                    flex-flow: row nowrap;
                                    justify-content: center;
                                    align-items: center;
                                    font-weight: ${themeBase.fontWeight.bold};
                                    font-size: 1.03rem;
                                    line-height: 1.35rem;
                                    column-gap: 0.25rem;
                                    svg {
                                        margin-right: 0;
                                        width: 15px;
                                    }

                                    @media screen and (max-width: 1366px) {
                                        font-size: var(--spacing-300);
                                    }
                                }
                            }
                        }
                    }

                    &.checked {
                        border-color: var(--secondary-color);
                        background-color: var(--secondary-color-bleached);

                        @media screen and (min-width: 1366px) {
                            border-width: 2px;
                        }

                        .MuiFormControlLabel-label {
                            .label-composed {
                                .label-big {
                                    color: #35b793;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-entry {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            @media screen and (max-width: 1366px) {
                font-size: var(--spacing-200);
            }

            &.bordered {
                background-color: var(--paper-color);
                border: 1px solid ${themeBase.color.neutral400};
                border-radius: 0.25rem;
                padding: 0.5rem 0.75rem;
            }

            .label {
                flex: 1;
                text-align: left;
                line-height: 1rem;

                .subtitle {
                    font-size: var(--spacing-150);

                    @media screen and (min-width: 1366px) {
                        font-size: var(--spacing-200);
                    }
                }
            }

            .value {
                flex: 1;
                text-align: right;

                div {
                    line-height: 1rem;

                    &.text-bold {
                        font-weight: 500;
                    }
                }
            }
        }

        hr {
            margin: 0.625rem 0;
            color: ${themeBase.color.neutral200};
            opacity: 1;
        }

        .error {
            font-size: 0.75rem;
            color: ${themeBase.color.error600};
            text-align: left;
        }
    }

    .simulation-card-footer {
        font-size: 1rem;
        font-weight: 500;
        color: #35b793;
    }
`;

const remunerationModelsButtonCSS = css`
    width: 300px;
    min-height: 49px;
    padding: 1rem;
    border-radius: 5px;

    @media (min-width: 1366px) {
        width: 355px;
        padding: 1rem 1.5rem;
    }

    &:hover {
        cursor: auto;
    }

    .simulation-card {
        ${simulationCardCSS};
    }
`;

const remunerationModelsButtonSelectedCSS = css`
    ${remunerationModelsButtonCSS};
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color-bleached);

    .simulation-card {
        &-banner {
            background-color: rgba(0, 163, 224, 0.15);
            color: var(--secondary-color);
        }

        &-header {
            .title svg path {
                fill: var(--secondary-color) !important;
            }

            * {
                color: var(--secondary-color);
            }
        }
    }
`;

const topMenuBgGreenCSS = css`
    position: absolute;
    z-index: 2;
    width: 40%;
    height: 100%;
    background: linear-gradient(180deg, #045931 0%, #05784b 52.6%, #058f5c 100%);

    ${media.tablet`
                    width: 100%;
                `}
`;

const landingPageContainerTopCSS = css`
    width: 100%;
    min-height: calc(50vh - 70px);
    position: relative;
    overflow: hidden;

    // @include bootstrap.media-breakpoint-down(md) {
    //   min-height: calc(63vh - 70px);
    // }
    @media (max-height: 756px) {
        min-height: 360px;
        // .landing-page-container-top {
        // }
    }

    .top-menu {
        position: absolute;
        z-index: 3;
        width: 60%;
        height: 100%;

        ${media.tablet`
                    width: 100%;
                    text-align: center;
                `}

        &-section {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 3rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            color: #fff;

            h2 {
                font-size: 1.75rem;
                line-height: 2rem;
                margin-bottom: 1.5rem;
                font-weight: 700;
            }

            h3 {
                font-size: 1.25rem;
                line-height: 1.5rem;
                margin-bottom: 1.5rem;
            }

            h4 {
                font-size: 1.125rem;
                font-weight: ${themeBase.fontWeight.medium};
                line-height: 1.35rem;
                margin-bottom: 1.5rem;
            }

            @media screen and (min-width: 970px) {
                padding: 6rem;
            }

            ${media.tablet`
                        align-items: center;
                        padding: 4rem 2rem;
                    `}

            .bt-landing-page {
                min-width: 7.5rem; // 120px
            }

            .top-menu-bullets-container {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                margin-bottom: 1.5rem;

                .top-menu-bullet {
                    flex: 1 1 calc(50% - 0.5rem);
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 1rem;
                    font-size: 0.875rem;
                    line-height: 1rem;
                    font-weight: ${themeBase.fontWeight.normal};
                    text-align: left;

                    &-content {
                        span {
                            display: block;
                            font-size: 10px;
                            margin-top: 4px;
                        }
                    }

                    @media screen and (max-width: 599px) {
                        flex: 1 1 100%;
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                        min-width: 30px;
                    }
                }
            }
        }

        img {
            height: 100%;
            width: 100%;

            ${media.tablet`
                        display: none;
                    `}
        }
    }

    .top-image {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        img {
            position: absolute;
            top: 0;
            right: 0;
            object-fit: cover;
            height: 100%;
            width: 45%;

            @media screen and (min-width: 970px) {
                top: 0;
                right: 0;
                width: 45%;
                object-fit: cover;
            }

            @media screen and (min-width: 1800px) {
                height: 512px;
            }

            ${media.tablet`
                        display: none;
                    `}
        }

        svg {
            @media screen and (min-width: 1800px) {
                bottom: 64px !important;
            }
        }

        .bg-over-img {
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(63, 63, 63, 0.25) 0%, rgba(9, 31, 44, 0.5) 100%);
            box-shadow: 0px 1.6129px 1.6129px rgba(0, 0, 0, 0.15);
        }
    }

    .top-menu-bg-green {
        ${topMenuBgGreenCSS};
    }
`;

const navCSS = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    min-height: fit-content;

    .spv-simple-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px var(--spacing-600);
        min-height: fit-content;
        background-color: #fafafa;

        // @media screen and (max-width: 600px) {
        //   background-color: transparent;
        // }

        @media (min-width: 660px) {
            padding: 21px var(--spacing-700);
        }

        &-left {
            height: 100%;
            align-items: center;

            .step-id {
                padding: 0;
                column-gap: 0.5rem;
                display: flex;
                gap: 1rem;
                align-items: center;

                svg {
                    > circle {
                        stroke: var(--secondary-color);
                        opacity: 0.35;
                    }

                    g {
                        path {
                            fill: var(--secondary-color);
                        }

                        circle {
                            fill: var(--secondary-color);
                        }
                    }

                    @media screen and (max-width: 600px) {
                        width: 40px;
                    }
                }

                .step-name {
                    //font-family: "Poppins";
                    font-size: 1rem;
                    font-weight: 500;
                    //text-transform: uppercase;
                    //letter-spacing: 0.1em;
                    line-height: 21px;
                    color: #202020;
                }
            }
        }

        &-right {
            height: 42px;
            column-gap: 0.75rem;

            .button-steps {
                ${buttonStepsCSS};

                &.selected {
                    ${buttonStepsCSS};
                    color: var(--secondary-color);
                }
            }

            .button-save {
                min-height: 100%;
                min-width: 63px;
            }
        }
    }

    .MuiCollapse-root {
        z-index: 100;
        position: fixed;
        top: 150px;
        width: 100%;
        height: fit-content;
        background-color: #fafafa;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
        overflow-y: scroll;
        overflow-x: hidden;

        @media (min-width: 660px) {
            top: 162px;
        }

        //#region enable scroll
        @media (max-height: 756px) {
            bottom: 0;
        }

        @media (min-width: 660px) and (max-height: 768px) {
            bottom: 0;
        }

        //#endregion enable scroll
    }

    .steps-menu {
        background-color: #fafafa;
        display: flex;
        flex-direction: column;
        margin: var(--spacing-600) var(--spacing-500) var(--spacing-750);
        row-gap: 1rem;

        @media (min-width: 660px) {
            margin: 6px var(--spacing-700) var(--spacing-800);
        }

        &-item {
            ${stepsMenuItemCSS};

            &.visited {
                ${stepsMenuItemCSS};
                background-color: var(--paper-color);
                border: 1px solid #cccccc;
                color: var(--primary-color);
            }

            &-title {
                //font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 500;
                //text-transform: uppercase;
                line-height: 30px;
                letter-spacing: 0.1em;
            }
        }

        .start-over-btn {
            margin-top: 1rem;
            justify-content: flex-start;
        }
    }
`;

export const StyledSPVSimplePage = styled.div`
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1366px) {
        min-height: calc(100vh - 70px - 52px);

        &:not(.landing) {
            margin: 0 5.25rem;
        }
    }

    // FIXME: temporary solution!
    .MuiButtonBase-root {
        text-transform: none !important;
    }

    .nav {
        ${navCSS};
    }

    .nav-desktop {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        margin: 1.5rem 0;

        .product-title {
            font-size: 1.25rem;
            font-weight: ${themeBase.fontWeight.semibold};
        }

        .steps-bar-tabs {
            .MuiTabs-indicator {
                display: flex;
                justify-content: flex-start;
                background-color: transparent;
                width: 100% !important;
                left: 0 !important;

                > .indicator {
                    flex: 1;
                    background-color: var(--primary-color);
                    max-width: 100%; // uses max-width of each tab

                    &.visited {
                        opacity: 0.2;
                    }

                    &.disabled {
                        background-color: ${themeBase.color.neutral200};
                    }
                }
            }

            .tab {
                max-width: 100%;
                flex: 1;
                justify-content: flex-start;
                min-height: 62px;

                &.current,
                &.visited {
                    background-color: rgba(0, 15, 30, 0.05) !important;
                    color: var(--primary-color) !important;
                }

                &.visited {
                    color: rgba(0, 15, 30, 0.5) !important;

                    svg {
                        path {
                            opacity: 0.5;
                        }
                    }
                }

                &.disabled {
                    // overrides .Mui-disabled
                    background-color: ${themeBase.color.neutral50} !important;
                    color: ${themeBase.color.neutral400} !important;

                    svg {
                        path {
                            fill: ${themeBase.color.neutral200};
                        }
                    }
                }
            }
        }
    }

    .solarpv-simple-form {
        position: relative;
        flex-grow: 1;
    }

    .nav-bottom {
        ${navCSS};
        width: 100%;
        z-index: 10;
        // filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.08));
        background-color: white;
        // @media (min-width: 600px) {
        position: sticky;
        bottom: 0;

        // }
        &-mobile-map-address {
            ${navCSS};
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: white;
        }

        &-desktop {
            ${navCSS};
            max-width: 100%;
            background-color: transparent;
            box-shadow: none;

            .spv-simple-nav {
                background-color: transparent;
                padding: 2.5rem 0 0;
            }
        }
    }

    .housing-type-wrapper {
        margin: var(--spacing-600) var(--spacing-700);
        display: flex;
        flex-flow: column nowrap;
        row-gap: 1.5rem;
        line-height: 1.5rem;

        &.htw-b2b {
            margin: 0;

            @media screen and (max-width: 1366px) {
                margin: var(--spacing-600) var(--spacing-700);
            }
        }

        @media screen and (min-width: 1366px) {
            width: fit-content;
            margin: auto;
        }

        .housing-type-container {
            padding: var(--spacing-400) var(--spacing-600) var(--spacing-600);
            border: 1px solid #e5e5e5;
            background-color: var(--paper-color);
            border-radius: 5px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
            flex-flow: column nowrap;

            h2 {
                //font-family: "Ping LCG", 'Open Sans';
                font-weight: ${themeBase.fontWeight.bold};
                font-size: 1.142857rem;
                text-align: center;
                margin-bottom: 1.125rem;

                @media (min-width: 660px) {
                    text-align: left;
                    font-size: 1rem;
                }
            }

            .housing-type-buttons {
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                gap: var(--spacing-500);
                padding-inline: 2.571428rem;

                @media (min-width: 660px) {
                    flex-flow: row nowrap;
                    gap: var(--spacing-600);
                    padding-inline: 0;
                }

                .housing-type-button {
                    ${housingTypeButtonCSS};

                    &.selected {
                        ${housingTypeButtonCSS};
                        background-color: var(--secondary-color-bleached, --primary-color-bleached);
                        border: 1px solid var(--secondary-color, --primary-color);

                        label {
                            color: var(--secondary-color, --primary-color);
                            font-weight: 500;
                        }

                        svg:not(.selected) {
                            path {
                                fill: var(--secondary-color, --primary-color);
                            }
                        }
                    }
                }
            }
        }

        .housing-type-container-b2b {
            ${housingTypeContainerCSS};
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .housing-type-faq {
            flex-flow: row nowrap;
            align-items: center;
            font-size: 0.875rem;
            font-weight: 500;

            .link {
                color: var(--secondary-color);
                cursor: pointer;
            }
        }
    }

    .address-container {
        display: flex;
        justify-content: center;
        height: 100%;

        @media screen and (min-width: 660px) {
            justify-content: flex-start;
            width: calc(386px + var(--spacing-600) + var(--spacing-600)); // mockup width + margin-left + margin-right

            &.clickable {
                width: calc(424.5px + var(--spacing-600) + var(--spacing-600)); // mockup width + margin-left + margin-right
            }

            &.drawable {
                width: calc(502px + var(--spacing-600) + var(--spacing-600)); // mockup width + margin-left + margin-right
            }
        }

        .map-container {
            position: absolute;
            width: 100%;
            // height: 100%;
            height: calc(100% - 82px);

            @media screen and (min-width: 1366px) {
                height: 100%;
            }
        }

        .search-card {
            display: flex;
            flex-flow: column nowrap;
            row-gap: var(--spacing-100);
            position: relative;
            width: 100%;
            margin: var(--spacing-600);
            margin-bottom: 0;
            padding: var(--spacing-200);
            background-color: rgba(246, 249, 251, 0.95);
            box-shadow: 0px 2.37262px 2.37262px rgba(0, 0, 0, 0.15);

            &.collapsed {
                row-gap: 0 !important;
                padding: 0.75rem !important;

                .card-content {
                    display: flex;
                    align-items: center;
                    height: 2rem;
                }
            }

            .card-content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                column-gap: 1.5rem;

                .MuiAutocomplete-root,
                .draw-roof-area {
                    flex: 1;
                }

                .toggle-collapse {
                    min-width: fit-content;
                    justify-content: center;
                    padding-right: 0.25rem;
                    padding-left: 0.25rem;

                    svg {
                        width: 22px;
                        height: 12px;
                    }

                    &:hover {
                        background-color: inherit;
                    }
                }
            }

            .MuiCardHeader {
                &-root {
                    padding: 0;
                }

                &-avatar {
                    display: grid;
                    place-items: center;
                    min-height: 100%;
                    aspect-ratio: 1;
                    margin-right: var(--spacing-150);

                    svg {
                        width: auto;
                        height: 2rem;

                        &.pin-icon {
                            fill: var(--primary-color);

                            path {
                                stroke: var(--primary-color);
                            }
                        }
                    }
                }

                &-content,
                &-content .MuiTypography-root {
                    //font-family: "Ping LGC", "Poppins", sans-serif;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 2rem;
                    color: var(--primary-color);

                    @media screen and (max-width: 660px) {
                        font-weight: ${themeBase.fontWeight.semibold};
                    }
                }
            }

            .MuiCardContent-root {
                padding: 0;

                .MuiTypography-root {
                    margin: 0;
                }

                .MuiInputBase {
                    &-root {
                        width: 100%;
                        height: 3rem;
                        background-color: #fff;

                        .MuiInputAdornment-root {
                            margin-left: 1rem;
                            margin-right: 0.5rem;

                            svg {
                                width: 22px;
                                height: 22px;
                            }

                            &.MuiInputAdornment-positionStart {
                                svg {
                                    fill: var(--primary-color);

                                    path {
                                        stroke: var(--primary-color);
                                    }
                                }
                            }

                            &.MuiInputAdornment-positionEnd {
                                svg {
                                    fill: ${themeBase.color.neutral600};

                                    path {
                                        stroke: ${themeBase.color.neutral600};
                                    }
                                }
                            }
                        }
                    }

                    &-input {
                        padding: var(--spacing-200) var(--spacing-150);
                        //font-family: "Ping LGC", "Poppins", sans-serif;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.214285rem;
                        color: var(--primary-color);
                    }
                }
            }

            .MuiTypography-root {
                hr {
                    margin: 0 0 0.75rem !important;
                    border-color: ${themeBase.color.info600};
                    opacity: 0.2;
                }

                .MuiCardHeader-root {
                    margin-bottom: 0.25rem;

                    .MuiTypography-root {
                        line-height: 2rem;
                    }
                }

                .card-subtitle {
                    font-size: 0.875rem;
                    line-height: 1.5rem;

                    strong {
                        font-weight: 500;
                    }

                    @media screen and (min-width: 660px) {
                        font-size: 0.75rem;
                    }
                }
            }

            @media (min-width: 660px) {
                &.identify {
                    row-gap: var(--spacing-100);
                }
            }
        }

        .draw-roof-area-separator {
            margin: 0 !important;
            border-color: var(--secondary-color);
            opacity: 0.2;
        }

        .draw-roof-area {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            hr {
                display: none;
            }

            &-left {
                display: flex;
                flex-direction: column;
                row-gap: 0.75rem;
                flex: 0 0 70%;
                margin-right: 1rem;

                @media screen and (max-width: 1366px) {
                    flex: 0 0 55%;
                    margin-right: 1.5em;
                }

                .hint {
                    font-size: 0.875rem;
                }

                @media screen and (max-width: 455px) {
                    width: 100%;
                    height: 100%;
                    justify-content: space-evenly;
                }
            }

            &-right {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: center;
                column-gap: 2rem;
                height: 100%;
                flex: 1;

                @media screen and (max-width: 455px) {
                    align-items: flex-start;

                    div[role='button'] {
                        // Lottie animation
                        height: 70% !important;
                    }
                }

                .area-container {
                    color: var(--primary-color);
                    font-weight: ${themeBase.fontWeight.semibold};

                    .area-value {
                        font-size: 1.25rem;
                    }

                    .area-unit {
                        font-size: 0.875rem;
                    }
                }
            }
        }
    }

    .roof-id-container {
        padding: var(--spacing-600) var(--spacing-700) 0;

        @media (min-width: 660px) {
            padding: var(--spacing-600) var(--spacing-900) 0;
        }

        .roof-id-list {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
            margin: 1.5rem auto 0;
            max-width: fit-content;

            .link {
                color: var(--secondary-color);
                cursor: pointer;
            }

            &-element {
                display: flex;
                flex-direction: row;
                column-gap: var(--spacing-400);
                align-items: center;
                //font-family: "Ping LCG", "Poppins", sans-serif;
                font-size: 1rem;
                font-weight: 500;
                line-height: 1.5rem;
                color: var(--primary-color);

                svg {
                    min-width: 30px;
                }
            }
        }
    }

    .roof-details-container {
        .roof-details-map {
            &-area {
                display: flex;
                padding: 0.5rem 1rem;
                position: absolute;
                background: #f1f8ff;
                border: 1px solid #00a3e0;
                border-radius: 5px;
                z-index: 1;
                top: 1rem;
                left: 1rem;

                .area-value,
                .area-unit {
                    font-family: 'Open Sans';
                    font-weight: 400;
                    font-size: 0.875rem;
                    text-align: center;
                    color: #00a3e0;
                }
            }

            .alert {
                margin-top: 0.5rem;

                .MuiAlert-icon {
                    margin-top: -20px;
                    margin-right: 10px;
                    padding: 0;

                    .MuiSvgIcon-root {
                        fill: var(--secondary-color);
                    }
                }

                &-text {
                    line-height: 1.5rem;
                    color: var(--primary-color);

                    span {
                        font-size: 0.875rem;
                        font-weight: ${themeBase.fontWeight.normal};
                        margin-right: 0.15rem;

                        .link {
                            color: var(--secondary-color);
                            cursor: pointer;
                        }
                    }
                }
            }

            @media (min-width: 660px) {
                .alert {
                    .MuiAlert-icon {
                        margin-top: 0;
                    }

                    &-text {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .roof-details-form {
            margin-top: 1.5rem;

            .roof-type-button {
                min-width: 162px;
                border-radius: 5px;
                color: var(--primary-color);

                &-icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                }

                &-text {
                    flex: 1;
                }
            }
        }
    }

    .roof-details-container,
    .consumption-details-container,
    .simulation-container,
    .successful-container {
        padding: var(--spacing-600);

        h2 {
            //font-family: "Poppins";
            font-size: 1rem;
            font-weight: ${themeBase.fontWeight.bold};
            line-height: 1.357142rem;
            color: var(--primary-color);
            margin-bottom: 1.5rem;

            hr {
                margin: var(--spacing-100) 0 var(--spacing-600);
                width: 126px;
                border: 1px solid var(--secondary-color);
                opacity: 1;
            }

            @media (min-width: 660px) {
                line-height: 1.5rem;

                hr {
                    margin-bottom: var(--spacing-200);
                }
            }
        }

        .map {
            position: relative;
            border: 1px solid transparent;
            border-radius: 10px;
            height: 180px;
            overflow: hidden;

            &-container {
                height: 180px !important;
            }
        }

        @media screen and (min-width: 660px) {
            padding: var(--spacing-600) var(--spacing-750);

            .map,
            .map-container {
                height: 300px !important;
            }
        }

        @media screen and (max-width: 575px) {
            padding: var(--spacing-600);

            .map,
            .map-container {
                height: 200px !important;
            }
        }

        @media screen and (min-width: 1366px) {
            padding: 0 0 var(--spacing-600);

            .roof-details-map,
            .simulation-map {
                width: calc(5 / 12 * 100%) !important;
            }

            .map,
            .map-container {
                height: 300px !important;
            }
        }
    }

    .input-container {
        position: relative;
        display: flex;
        flex-flow: column nowrap;

        label {
            margin-bottom: var(--spacing-100);
            font-weight: 500;
            color: var(--primary-color);
            font-size: var(--spacing-300);
            //font-family: "Ping LGC", "Poppins", sans-serif;
        }

        .radio-with-label {
            display: flex;
            flex-flow: row wrap;
            gap: var(--spacing-200);

            .MuiButtonBase-root {
                display: flex;
                align-items: center;
                column-gap: var(--spacing-150);

                border: 1px solid ${themeBase.color.neutral400};
                border-radius: 5px !important;
                padding: var(--spacing-150) var(--spacing-400);
            }
        }
    }

    .consumption-details-container {
        h2 {
            hr {
                margin: 0.5rem 0 2rem;
                width: 126px;
                height: 1px;
                border: 1px solid var(--secondary-color);
                opacity: 1;
            }
        }

        .consumption-details-form {
            @media screen and (min-width: 1366px) {
                width: 50%;
            }

            .input-container {
                ${inputContainerCSS};

                .radio-with-label {
                    display: flex;
                    flex-flow: row wrap;
                    column-gap: 0.75rem;

                    .number-phases-button {
                        ${numberPhasesButtonCSS};

                        &.selected {
                            ${numberPhasesButtonCSS};
                            color: var(--secondary-color);
                            border: 1px solid var(--secondary-color);
                            background-color: var(--secondary-color-bleached);
                        }
                    }
                }

                &-equipments {
                    ${inputContainerCSS};
                    flex: 0 0 100%;
                    min-width: 100%;

                    .equipments-container {
                        display: flex;
                        flex-direction: column;

                        &-item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            flex: 0 0 100%;
                            min-width: 100%;

                            .MuiCheckbox-root {
                                padding-left: 0;
                                padding-right: 0;

                                &.Mui-checked {
                                    svg {
                                        > rect {
                                            fill: var(--secondary-color);
                                            stroke: var(--secondary-color);
                                        }
                                    }
                                }
                            }

                            &-label {
                                display: flex;
                                flex-flow: row nowrap;
                                align-items: center;
                            }
                        }

                        .equipment-icon {
                            min-width: 25px;

                            &.checked {
                                path {
                                    fill: var(--text-color);
                                }
                            }
                        }

                        .equipment-label {
                            font-size: 0.875rem;

                            &.unchecked {
                                color: ${themeBase.color.neutral600};
                            }
                        }

                        hr {
                            min-width: 100%;
                            margin-top: 0.25rem;
                            margin-bottom: 0.5rem;
                        }
                    }
                }

                .unit {
                    color: ${themeBase.color.neutral400};
                }

                @media screen and (max-width: 768px) {
                    padding: 0;
                }
            }
        }
    }

    .simulation-container {
        h2 {
            hr {
                margin: 0.5rem 0 2rem;
                width: 126px;
                height: 1px;
                border: 1px solid var(--secondary-color);
                opacity: 1;
            }
        }

        .simulation-map {
            margin-bottom: 1.5rem;

            .map {
                .exclusions-legend {
                    position: absolute;
                    bottom: 1rem;
                    left: 1rem;
                    padding: 0.5rem 1rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    column-gap: 0.5rem;
                    background-color: ${themeBase.color.info50};
                    border: 1px solid ${themeBase.color.info};
                    border-radius: 5px;

                    .square {
                        width: 1rem;
                        height: 1rem;
                        background-color: ${themeBase.color.info200};
                        border: 1px solid ${themeBase.color.info};
                    }

                    &-title {
                        font-size: 0.75rem;
                        font-weight: 500;
                        color: ${themeBase.color.info};
                    }
                }
            }
        }

        .simulation-form-wrapper {
            margin-bottom: 2rem;
        }

        @media screen and (min-width: 1366px) {
            .simulation-form-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .skeletons {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .simulation-form,
            .contacts-card,
            .skeletons,
            .skeletons-right {
                width: 100%;
                margin: 0 !important;

                .disclaimer {
                    font-size: 0.75rem !important;
                    border-bottom: none !important;
                    width: 60%;
                }
            }

            .contacts-card,
            .spv-simple-grants {
                width: 45%;
            }
        }

        .simulation-form {
            display: flex;
            flex-flow: column nowrap;
            row-gap: var(--spacing-600);

            .remuneration-models-group-button {
                @media (max-width: 959px) {
                    flex-flow: column nowrap;
                    align-items: center;
                }

                .remuneration-models-button {
                    ${remunerationModelsButtonCSS};
                    background-color: unset;

                    &.selected {
                        ${remunerationModelsButtonSelectedCSS};
                    }
                }

                .remuneration-models-button-b2b {
                    ${remunerationModelsButtonCSS};

                    &.selected {
                        ${remunerationModelsButtonSelectedCSS};

                        .simulation-card {
                            &-banner {
                                background-color: rgba(0, 15, 30, 0.1) !important;
                                color: var(--primary-color) !important;
                            }

                            &-header {
                                .title {
                                    .company-title {
                                        * {
                                            color: var(--primary-color) !important;
                                        }

                                        &-product {
                                            color: #38aa34 !important;
                                        }

                                        svg path {
                                            fill: var(--primary-color) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media screen and (max-width: 1365px) {
                        .simulation-card {
                            &-header {
                                .company-title {
                                    svg {
                                        margin-right: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .disclaimer {
                margin: 0;
                padding: 0 0 var(--spacing-600) 0;
                border-bottom: 1px solid ${themeBase.color.neutral200};
                //font-family: "Poppins", sans-serif;
                font-size: 0.875rem;
                font-weight: ${themeBase.fontWeight.normal};
                line-height: 18px;

                @media screen and (max-width: 768px) {
                    font-size: var(--spacing-200);
                }
            }
        }

        .contacts-card {
            display: flex;
            flex-direction: column;
            background-color: ${themeBase.color.info50};
            border: 1px solid ${themeBase.color.info600};
            border-radius: 5px;
            row-gap: 1.5rem;
            padding: 1.5rem 2rem;
            margin: 1.5rem 0 2.5rem;

            &-title {
                //font-family: "Poppins";
                font-size: 1rem;
                font-weight: 500;
                color: var(--primary-color);
            }

            &-form {
                display: flex;
                flex-direction: column;
                row-gap: 0.75rem;

                .input-container {
                    background-color: var(--paper-color);

                    .Mui-disabled {
                        background-color: ${themeBase.color.neutral200};
                    }

                    .efz-phone .react-tel-input {
                        font-family: 'Ping LCG', 'Open Sans';

                        .form-control {
                            font-size: 1rem;
                        }

                        :disabled {
                            background-color: ${themeBase.color.neutral200};
                            opacity: 1;
                            -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
                            cursor: auto;
                        }
                    }
                }

                .MuiFormHelperText-root.Mui-error {
                    background-color: ${themeBase.color.info50};
                }

                .MuiFormHelperText-root.Mui-error {
                    background-color: ${themeBase.color.info50};
                }

                .MuiFormHelperText-root.Mui-error {
                    background-color: ${themeBase.color.info50};
                }
            }

            &-terms {
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 1rem;
                font-size: 0.875rem;
                font-weight: ${themeBase.fontWeight.normal};

                .link {
                    color: var(--secondary-color);
                    cursor: pointer;
                    font-weight: 500;
                }
            }
        }
    }

    .successful-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h2 {
            hr {
                margin: 0.5rem 0 2rem;
                width: 126px;
                height: 1px;
                border: 1px solid var(--secondary-color);
                opacity: 1;
            }
        }

        &-card {
            border: 1px solid ${themeBase.color.success};
            background-color: ${themeBase.color.success50};
            border-radius: 8px;
            text-align: center;
            min-width: 31.25rem;

            &-content {
                max-width: 550px;
                margin: 25px auto;

                h3 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;
                }
            }
        }

        &-link {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 1rem;

            p {
                font-size: 1rem;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 0.9rem;
                margin-bottom: 10px;
                a {
                    margin-top: 0;
                    gap: 5px;
                    svg {
                        width: 0.9rem;
                    }
                }
                span {
                    text-decoration: underline;
                    &:hover {
                        cursor: pointer;
                    }
                }
                &:first-of-type {
                    font-family: 'Poppins', 'Open Sans';
                    font-weight: 600;
                }
            }

            a {
                display: flex;
                margin-top: 1rem;
                font-size: 0.875rem;
                color: #00a3e0;
                font-weight: 400;

                &:hover {
                    cursor: pointer;

                    p {
                        text-decoration: underline;
                    }
                }
            }
        }

        &-restart {
            display: flex;
            justify-content: center;
            margin-top: 30px;
        }

        .start-over-button {
            height: 42px;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 0.5rem;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);

            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    .container-check {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .container-central-animation {
        width: 80px;
        height: 80px;
        margin: 0 0 0.75rem;
    }

    .check-icon.check-success {
        border-color: #4caf50;
    }

    .check-icon.check-success::before,
    .check-icon.check-success::after {
        content: '';
        -webkit-border-radius: 40px;
        border-radius: 40px;
        border-radius: 50%;
        position: relative;
        width: 60px;
        height: 120px;
        background: white;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .check-icon.check-success::before {
        -webkit-border-radius: 120px 0 0 120px;
        border-radius: 120px 0 0 120px;
        top: -7px;
        left: -33px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 60px 60px;
        transform-origin: 60px 60px;
        position: relative;
    }

    .check-icon.check-success::after {
        -webkit-border-radius: 0 120px 120px 0;
        border-radius: 0 120px 120px 0;
        top: -11px;
        left: 30px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        -webkit-transform-origin: 0px 60px;
        transform-origin: 0px 60px;
        position: relative;
    }

    .check-icon.check-success .icon-placeholder {
        width: 80px;
        height: 80px;
        border: 4px solid rgba(76, 175, 80, 0.5);
        -webkit-border-radius: 40px;
        border-radius: 40px;
        border-radius: 50%;
        box-sizing: content-box;
        left: -3px;
        top: -12px;
        z-index: 2;
        position: relative;
    }

    .check-icon.check-success.animate::after {
        -webkit-animation: rotatePlaceholder 4.25s ease-in;
        animation: rotatePlaceholder 4.25s ease-in;
    }

    .check-icon.check-success {
        border-color: transparent;
    }

    .check-icon.check-success .line .tip {
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
    }

    .check-icon.check-success .line .long {
        transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
    }

    .animateSuccessTip {
        -webkit-animation: animateSuccessTip 0.75s;
        animation: animateSuccessTip 0.75s;
    }

    .animateSuccessLong {
        -webkit-animation: animateSuccessLong 0.75s;
        animation: animateSuccessLong 0.75s;
    }

    @-webkit-keyframes animateSuccessLong {
        0% {
            width: 0;
            left: 70px;
            top: 12px;
        }

        54% {
            width: 0;
            left: 70px;
            top: 12px;
        }

        70% {
            width: 47px;
            left: 26px;
            top: 34px;
        }

        84% {
            width: 31px;
            left: 29px;
            top: 39px;
        }

        100% {
            width: 47px;
            left: 26px;
            top: 34px;
        }
    }

    @-webkit-keyframes animateSuccessTip {
        0% {
            width: 0;
            left: 1px;
            top: 19px;
        }

        54% {
            width: 0;
            left: 1px;
            top: 19px;
        }

        70% {
            width: 50px;
            left: -8px;
            top: 37px;
        }

        84% {
            width: 17px;
            left: 21px;
            top: 48px;
        }

        100% {
            width: 25px;
            left: 14px;
            top: 45px;
        }
    }

    @keyframes animateSuccessLong {
        0% {
            width: 0;
            left: 70px;
            top: 12px;
        }

        54% {
            width: 0;
            left: 70px;
            top: 12px;
        }

        70% {
            width: 47px;
            left: 26px;
            top: 34px;
        }

        84% {
            width: 31px;
            left: 29px;
            top: 39px;
        }

        100% {
            width: 47px;
            left: 26px;
            top: 34px;
        }
    }

    @keyframes animateSuccessTip {
        0% {
            width: 0;
            left: 1px;
            top: 19px;
        }

        54% {
            width: 0;
            left: 1px;
            top: 19px;
        }

        70% {
            width: 50px;
            left: -8px;
            top: 37px;
        }

        84% {
            width: 17px;
            left: 21px;
            top: 48px;
        }

        100% {
            width: 25px;
            left: 14px;
            top: 45px;
        }
    }

    .check-icon.check-success .line {
        height: 5px;
        background-color: #4caf50;
        display: block;
        border-radius: 2px;
        z-index: 2;
        position: relative;
    }

    .check-icon.check-success .line.tip {
        width: 25px;
        left: 14px;
        top: 46px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: relative;
    }

    .check-icon.check-success .line.long {
        width: 47px;
        left: 26px;
        top: 34px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        position: relative;
    }

    .landing-page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        overflow: hidden auto;

        @media screen and (min-width: 1366px) {
            height: calc(100vh - 70px - 56px);
        }

        &-top {
            ${landingPageContainerTopCSS};
        }

        .top-menu-bg-b2b {
            ${topMenuBgGreenCSS};
            top: 0;
            height: 75% !important;
            background-image: url(${gradientBgPhone}) !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
        }

        &-top-b2b {
            ${landingPageContainerTopCSS};
            min-height: calc(60vh - 2px);

            @media screen and (max-width: 1366px) {
                min-height: calc(70vh - 70px);
            }

            @media screen and (max-width: 1360px) {
                min-height: calc(50vh - 70px);
                height: 500px;
            }

            @media screen and (max-height: 750px) {
                min-height: 75vh;
            }

            @media screen and (max-width: 599px) {
                min-height: calc(88vh - 70px);
                height: 600px;
            }

            .gradient-bg {
                transform-origin: left;
            }

            .top-menu {
                width: 100%;
                max-width: 60vw;

                @media screen and (max-width: 599px) {
                    padding: 2rem 0;
                    position: relative;
                    background-image: url(${gradientBgPhone});
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            img {
                display: unset !important;
            }

            @media screen and (max-width: 1360px) {
                .top-menu {
                    max-width: 100%;

                    &-section {
                        padding: 2rem 3rem;
                    }

                    &-half-width {
                        max-width: 60%;
                    }

                    &-full-width {
                        max-width: 100%;
                    }
                }
            }
        }

        &-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // margin: 2rem auto auto;

            h2 {
                font-size: 1.25rem;
                line-height: 2rem;
                margin: 2rem 1rem;
                font-weight: 500;
                color: #000f1e;

                hr {
                    width: 126px;
                    border: 2px solid var(--secondary-color);
                    margin: 0.5rem auto;
                    opacity: 1;
                }
            }

            .bottom-grid {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: 2rem;

                @media screen and (min-width: 970px) {
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }

                ${media.tablet`
                    gap: 1rem;
                `}

                &-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 1.5rem 0.75rem;

                    width: 190px;
                    height: 150px;
                    color: #fff;
                    font-size: 0.875rem;
                    line-height: 17px;
                    font-weight: 400;
                    text-align: center;

                    background-repeat: no-repeat;
                    background-size: cover;

                    ${media.tablet`
                        width: 140px;
                        height: 120px;
                        border-radius: 5px;
                        padding: 1rem;
                    `}

                    svg {
                        margin-bottom: 1rem;
                    }

                    &.savings {
                        background-image: url(${savings});
                    }

                    &.earth {
                        background-image: url(${earth});
                    }

                    &.energy {
                        background-image: url(${energy});
                    }

                    &.tech {
                        background-image: url(${tech});
                    }

                    &.b2b {
                        background-image: url(${cardBg});
                        border-radius: 0.25rem;
                    }
                }
            }
        }
    }

    .lbc-top-b2b {
        background: linear-gradient(
            157deg,
            rgba(176, 138, 75, 1) 0%,
            rgba(80, 131, 84, 1) 20%,
            rgba(80, 131, 84, 1) 45%,
            rgba(176, 138, 75, 1) 100%
        );
        display: flex;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 29%;
            height: 44%;
            background: linear-gradient(
                157deg,
                rgba(176, 138, 75, 1) 0%,
                rgba(80, 131, 84, 1) 20%,
                rgba(80, 131, 84, 1) 45%,
                rgba(176, 138, 75, 1) 100%
            );
            clip-path: polygon(0% 0%, 16.4% 0%, 7.6% 100%, 0% 100%);
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            width: 29%;
            height: 44%;
            background: linear-gradient(
                157deg,
                rgba(176, 138, 75, 1) 0%,
                rgba(80, 131, 84, 1) 20%,
                rgba(80, 131, 84, 1) 45%,
                rgba(176, 138, 75, 1) 100%
            );
            clip-path: polygon(92.3% 0%, 100% 0%, 100% 100%, 85.7% 100%);
        }

        .top-menu-section {
            width: 65%;
            position: relative;

            @media screen and (max-width: 1360px) {
                width: 75%;
            }

            @media screen and (max-width: 960px) {
                width: 100%;
            }
        }

        &-image {
            width: 45%;
            clip-path: polygon(100px 0, 70px 47%, 30px 47%, 0 100%, 100% 100%, 100% 0);

            @media screen and (max-width: 960px) {
                display: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .company-title {
        display: flex;
        align-items: center;
        color: var(--paper-color);
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;

        &-product {
            color: #38aa34;
        }

        svg {
            margin-right: 1rem;
        }

        &.black {
            color: black;

            svg {
                path {
                    fill: black;
                }
            }
        }
    }
`;

export const StyledSPVSimpleAddressSearchOptions = styled(Paper)`
    .MuiAutocomplete-noOptions,
    .MuiAutocomplete-loading,
    .MuiAutocomplete-listbox {
        margin-top: 0.5rem;
        width: 100%;
    }

    .MuiAutocomplete-listbox {
        padding: 1rem 1.75rem;
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;

        .MuiAutocomplete-option {
            //font-family: 'Poppins', 'Open Sans';
            padding: 0 !important;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            color: var(--primary-color);

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &.Mui-focused {
                background-color: var(--paper-color);
                font-weight: ${themeBase.fontWeight.semibold};
            }

            &::after {
                content: '';
                position: relative;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: 1px solid ${themeBase.color.neutral200};
                margin-top: 0.5rem;
            }
        }
    }

    @media screen and (max-width: 575px) {
        .MuiAutocomplete-listbox {
            row-gap: 1rem !important;
            max-height: none !important;
        }

        .MuiAutocomplete-option {
            min-height: fit-content !important;

            &::after {
                margin-top: 0.75rem !important;
            }
        }
    }
`;

export const StyledSPVSimpleGrants = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border-radius: 10px;
    row-gap: 1.5rem;
    padding: 1.5rem 2rem;
    margin: 1.5rem 0 2.5rem;

    h2 {
        margin: 0 !important;
    }

    .spv-simple-grants {
        &-question {
            &_options {
                display: flex;
                gap: 15px;
            }
        }

        &-options {
            &_select {
                min-width: 299px !important;
            }
        }

        &-data {
            display: flex;
            padding: 1rem 2rem;
            background-color: #f1f8ff;
            border: 1px solid #3885cd;
            border-radius: 3px;
            justify-content: center;
            align-items: center;

            &_tab {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                width: 50%;
                color: #00a3e0;

                &:first-of-type {
                    border-right: 1px solid #00a3e0;
                }

                p {
                    inline-size: 130px;
                }

                span {
                    font-weight: 500;
                    display: block;
                    position: relative;

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 105%;
                        height: 1px;
                        background-color: #00a3e0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                &.grant {
                    span {
                        &::after {
                            display: none;
                        }

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        color: #35b793;
                    }
                }
            }
        }

        &-warning {
            padding: 1rem 1.5rem;
            display: flex;
            background-color: #fbf9ea;
            border: 1px solid #d6c228;
            border-radius: 3px;
            gap: 10px;
            align-items: center;

            p {
                margin: 0;
            }
        }

        &-calculate {
            max-width: 180px;
            width: 100%;
            margin: 0 auto;
        }
    }
`;
