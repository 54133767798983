import { lazy } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// Services
import lazyLoader from 'services/lazyLoader';

// Utils
import { containString, isDefined } from 'services/util/auxiliaryUtils';

// Actions

const SiteSurveyProcessesPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/SiteSurvey')));
const SiteSurveyProcesssPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/SiteSurvey/Process')));
const SiteSurveyProcessDetails = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/SiteSurvey/Process/SiteSurveyDetails')));

const SiteSurveyProcesses = () => {
    const { id } = useParams();
    const location = useLocation();
    let container;

    SiteSurveyProcesssPage;
    if (isDefined(id)) {
        container = containString(['details'], location.pathname) ? <SiteSurveyProcessDetails /> : <SiteSurveyProcesssPage />;
    } else {
        container = <SiteSurveyProcessesPage />;
    }

    return container;
};

export default SiteSurveyProcesses;
