import { lazy } from 'react';
import { useParams } from 'react-router-dom';

// Services
import lazyLoader from 'services/lazyLoader';

// Utils
import { isDefined } from 'services/util/auxiliaryUtils';

// Actions

const OMProcessesPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/O&M')));
const OMDetailsPage = lazy(() => lazyLoader(() => import('pages/Restricted/Processes/O&M/Details.tsx')));

const OMProcesses = () => {
    const { id } = useParams();

    const container = isDefined(id) ? <OMDetailsPage /> : <OMProcessesPage />;

    return container;
};

export default OMProcesses;
