import { themeBase } from 'constants/styles/global';
import { css, styled } from 'styled-components';
import Drawer from '@mui/material/Drawer';
import Tooltip from 'components/@efz/Tooltip';

const marginRight = css`
    margin-right: 0.75rem;
`;
const sidebarIcon = css`
    margin-right: 0.75rem;

    &-opener {
        svg {
            path {
                stroke: var(--primary-color);
            }
        }
    }

    &-solutions {
        ${marginRight};

        svg {
            path {
                fill: ${themeBase.color.neutral800};

                &:first-of-type,
                &:last-of-type {
                    stroke: ${themeBase.color.neutral800};
                }
            }
        }

        &-selected {
            ${marginRight};

            svg {
                path {
                    fill: var(--primary-color);

                    &:first-of-type,
                    &:last-of-type {
                        stroke: var(--primary-color);
                    }
                }
            }
        }

        &-disabled {
            ${marginRight};

            svg {
                path {
                    fill: var(--disabled-color);

                    &:first-of-type,
                    &:last-of-type {
                        stroke: var(--disabled-color);
                    }
                }
            }
        }
    }

    &-processes {
        ${marginRight};

        svg {
            path {
                fill: ${themeBase.color.neutral800};
            }
        }

        &-selected {
            ${marginRight};

            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }

        &-disabled {
            ${marginRight};

            svg {
                path {
                    fill: var(--disabled-color);
                }
            }
        }
    }

    &-backoffice {
        ${marginRight};

        svg {
            path {
                fill: ${themeBase.color.neutral800};
                stroke: ${themeBase.color.neutral800};
            }
        }

        &-selected {
            ${marginRight};

            svg {
                path {
                    fill: var(--primary-color);
                    stroke: var(--primary-color);
                }
            }
        }

        &-disabled {
            ${marginRight};

            svg {
                path {
                    fill: var(--disabled-color);
                    stroke: var(--disabled-color);
                }
            }
        }
    }
`;

const sidebarButton = css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;
    min-width: 15.375rem;

    &:hover {
        background-color: #fafafa;
    }

    .sidebar-icon {
        ${sidebarIcon};
        margin-right: 0.75rem !important;
    }

    span {
        font-size: 0.875rem;
        color: black;
    }
`;

const sidebarButtonPermanent = css`
    ${sidebarButton};
    min-width: 0;
    transition: min-width 0.5s !important;

    .sidebar-icon {
        ${sidebarIcon};
        margin-right: 0 !important;
    }

    span {
        overflow: hidden;
        /* Hide the element content, while height = 0 */
        height: 0;
        opacity: 0;
        max-width: 0;
        width: 0;
        transition:
            height 0.5s,
            opacity 0.5s,
            max-width 0.5s;
    }

    &.open {
        .sidebar-icon {
            ${sidebarIcon};
            margin-right: 0.75rem !important;
        }

        min-width: 15.375rem !important;
        transition: min-width 0.5s !important;

        span {
            overflow: inherit;
            height: auto;
            opacity: 1;
            max-width: 15.375rem;
            transition:
                height 0s,
                opacity 0.5s,
                max-width 0.5s;
        }
    }
`;
const boSidebarTitle = css`
    font-size: 0.875rem;
    display: flex;
    align-items: center;

    svg {
        width: 1.875rem;
        margin-right: 0.5rem;
    }
`;
const backofficeSibebarButton = css`
    padding: 1.5rem;
    color: ${themeBase.color.neutral800};
    border-bottom: 1px solid #e5e5e5;
    min-height: 4.875rem;
    cursor: pointer;

    .sidebar-button-title {
        ${boSidebarTitle};
    }
`;

const bosidebarSections = css`
    .sidebar-button-sections {
        display: flex;
        flex-direction: column;
        color: var(--primary-color);
        margin-left: 2.5rem;
        text-overflow: ellipsis;
        margin-top: 0.625rem;

        .section {
            font-size: 0.875rem;
            line-height: 30px;

            &-selected {
                font-size: 0.875rem;
                line-height: 30px;
                text-decoration: underline;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
`;

export const StyledSidebar = styled(Drawer).attrs<{ $open?: boolean }>((props) => ({
    $open: props?.$open ?? false,
}))`
    z-index: 800 !important;
    position: relative !important;
    font-family: 'Poppins';

    .MuiPaper-root {
        top: 70px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
        overflow: inherit;
    }

    .MuiBackdrop-root {
        background-color: unset !important;
    }

    .sidebar-button {
        ${sidebarButton};

        .sidebar-icon {
            ${sidebarIcon};
        }

        &-selected {
            ${sidebarButton};
            background-color: #fafafa;

            span {
                color: var(--primary-color);
                font-weight: 500 !important;
            }
        }

        &-disabled {
            ${sidebarButton};

            span {
                color: var(--disabled-color);
            }
        }

        &-permanent {
            ${sidebarButtonPermanent};

            &-selected {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 1.5rem;
                border-bottom: 1px solid #e5e5e5;
                border-radius: 0;
                min-width: 15.375rem;
                background-color: #fafafa;
                min-width: 0;
                transition: min-width 0.5s !important;

                &:hover {
                    background-color: #fafafa;
                }

                span {
                    font-size: 0.875rem;
                    color: black;
                    color: var(--primary-color);
                    font-weight: 500 !important;
                }

                .sidebar-icon {
                    ${sidebarIcon};
                    margin-right: 0 !important;
                }

                span {
                    overflow: hidden;
                    /* Hide the element content, while height = 0 */
                    height: 0;
                    opacity: 0;
                    max-width: 0;
                    width: 0;
                    transition:
                        height 0.5s,
                        opacity 0.5s,
                        max-width 0.5s;
                    font-weight: 500 !important;
                }

                &.open {
                    .sidebar-icon {
                        ${sidebarIcon};
                        margin-right: 0.75rem !important;
                    }

                    min-width: 15.375rem !important;
                    transition: min-width 0.5s !important;

                    span {
                        overflow: inherit;
                        height: auto;
                        opacity: 1;
                        max-width: 15.375rem;
                        transition:
                            height 0s,
                            opacity 0.5s,
                            max-width 0.5s;
                    }
                }
            }

            &-disabled {
                ${sidebarButtonPermanent};

                span {
                    color: var(--disabled-color);
                }
            }
        }

        .sidebar-sections {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 0.625rem;
            margin-left: 2.75rem;
            gap: 0.625rem;
            width: 100%;
        }

        .sidebar-section {
            width: fit-content;

            &-selected {
                width: fit-content;
                text-decoration: underline;
            }

            &:hover {
                width: fit-content;
                color: var(--primary-color);
                text-decoration: underline;
            }
        }
    }

    .backoffice-sidebar {
        width: 15.5rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        background-color: ${themeBase.color.neutral50};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;

        &-button {
            ${backofficeSibebarButton};

            &-selected {
                ${backofficeSibebarButton};
                ${bosidebarSections};
                color: var(--primary-color);

                .sidebar-button-title {
                    ${boSidebarTitle};
                    font-weight: 500;
                    color: var(--primary-color);
                }
            }

            &-disabled {
                ${backofficeSibebarButton};
                color: var(--disabled-color);
                pointer-events: none;
                cursor: auto;
            }

            &:hover {
                background-color: ${themeBase.color.neutral100};
            }

            ${bosidebarSections};
        }
    }
`;

export const StyledSidebarTooltip = styled(Tooltip)`
    div.MuiTooltip-tooltip {
        margin-left: 2.375rem !important;
        margin-bottom: -1rem !important;
    }

    .efz-tooltip-content {
        padding-block: 0.5rem !important;
    }
`;
